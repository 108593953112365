<template>
  <div class="layout-config h-100 m-r-20">
    <Row type="flex" :gutter="10">
      <Col span="4">
        <Input
          v-model="param.query"
          placeholder="请输入研学活动名称"
          clearable
        />
      </Col>
      <Col span="4">
        <Button type="primary" @click="getData()">查询</Button>
        <Button
          type="success"
          class="m-l-5"
          to="/config/dataManage/activity/activityPage"
          >新增</Button
        >
      </Col>
    </Row>
    <Table
      border
      :columns="cols"
      :data="table.data"
      ref="table"
      class="m-t-10 w-100"
    />
    <Page
      :page="param.current"
      @on-change="getData"
      :page-size="10"
      :total="table.total"
      show-total
      class="text-r m-t-10"
    />
  </div>
</template>

<script>
import { getActivity, deleteData } from "@/libs/api/activity";
import { mapState } from 'vuex';
export default {
  data() {
    const _this = this;
    const dateFormat = (date) => {
      return this.$moment(date).format("yyyy-MM-DD HH:mm:ss");
    };
    return {
      param: {
        current: 1,
        size: 10,
        order: 'activityName'
      },
      table: {},
      cols: [
        {
          title: "序号",
          type: "index",
          key: "index",
          width: 70,
          align: "center",
        },
        { title: "活动名称", key: "activityName" },
        { title: "开始时间", key: "startTime" },
        { title: "结束时间", key: "endTime" },
        {
          title: "操作",
          width: 400,
          render(h, w) {
            const btns = []
            
            btns.push(h('Button', {
              props: { size: "small" },
              on: {
                click() {
                  _this.$router.push({
                    path: "/config/dataManage/activity/activityTotal",
                    query: { id: w.row.activityId },
                  })
                }
              }
            }, '汇总'))
            if(_this.isAuth) {
              btns.push(h('Button', {
                props: { type: "info", size: "small" },
                on: {
                  click() {
                    _this.$router.push({
                      path: "/config/dataManage/activity/activityResource",
                      query: { id: w.row.activityId },
                    })
                  }
                }
              }, '查看资源'))
              btns.push(h('Button', {
                props: { type: "success", size: "small" },
                on: {
                  click() {
                    _this.$router.push({
                      path: "/config/dataManage/activity/activityResourcePage",
                      query: { id: w.row.activityId },
                    })
                  }
                }
              }, '添加资源'))
            }
            btns.push(h(
                "Button",
                {
                  props: { type: "info", size: "small" },
                  on: {
                    click() {
                      _this.$router.push({
                        path:
                          "/config/dataManage/activity/activityView",
                        query: { id: w.row.activityId },
                      });
                    },
                  },
                },
                "查看"
              ), h(
                "Button",
                {
                  props: { type: "success", size: "small" },
                  on: {
                    click() {
                      _this.$router.push({
                        path:
                          "/config/dataManage/activity/activityPage",
                        query: { id: w.row.activityId },
                      });
                    },
                  },
                },
                "编辑"
              ), h(
                "Button",
                {
                  props: { type: "error", size: "small" },
                  on: {
                    click() {
                      _this.$Modal.confirm({
                        title: "删除",
                        content: `是否删除当前${w.row.activityName}?`,
                        onOk: () => {
                          deleteData({ activity_id: w.row.activityId }).then(
                            () => {
                              _this.$Message.success("删除成功！");
                              _this.getData();
                            }
                          );
                        },
                      });
                    },
                  },
                },
                "删除"
              ))
            return btns
          },
        },
      ],
    };
  },
  computed: {
    ...mapState({
      user: state => state.user,
      roleCode: state => state.user.roleCode,
      isAuth: state => state.user.isAuth,
    })
  },
  methods: {
    // 获取数据列表
    getData(page) {
      Object.assign(this.param, { current: page ? page : 1 });
      getActivity({
        owner_id: this.$store.state.user.userId,
        ...this.param,
      }).then((res) => {
        res.code == "HA0200"
          ? (this.table = res)
          : this.$Message.error(res.msg);
      });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style></style>
