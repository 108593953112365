import { request } from '@/libs'

/**
 * 研学活动API
 */

// 分页查询用户所创建的活动列表
export function getActivity(params) {
    return request.post('/yanxue-app/activity/list', params)
}

//  查询用户所有参与的研学活动
export function getActivityByJoin(params) {
    return request.post(`/yanxue-app/activity/join?user_id=${params.userId}`, params)
}

// // 分页查询
// export function getData(params) {
//     return request.post(`/yanxue-app/research/activity/findbypage/namelike?ownerId=${params.userId}`, params)
// }

// 根据id获取研学活动详情
export function getDataById(params) {
    return request.get('/yanxue-app/activity/one', { params })
}

// 新增研学活动
export function addData(params) {
    return request.post('/yanxue-app/activity/normal', params)
}

// 新增临时研学活动
export function addTempData(params) {
    return request.post('/yanxue-app/activity/temp', params)
}

// 修改研学活动
export function updateData(params) {
    return request.put(`/yanxue-app/activity/normal?activity_id=${params.activityId}`, params)
}

// 根据活动id解散活动
export function deleteData(params) {
    return request.delete('/yanxue-app/activity/normal', { params })
}

// 同意当前活动请求
export function agreeData(params) {
    return request.post(`/yanxue-app/activity/agree?activity_id=${params.activityId}`)
}

// 拒绝当前活动请求
export function disagreeData(params) {
    return request.post(`/yanxue-app/activity/disagree?activity_id=${params.activityId}`)
}

// 根据分享码获取分享的活动数据
export function getShareActivity(params) {
    return request.get(`/yanxue-app/activity/share/snapshot/${params.base}`)
}

// 自动分页获取分享列表
export function getShareActivityList(params) {
    return request.post('/yanxue-app/activity/share/page', params)
}

// 修改｜更新分享
export function updateShareActivity(params) {
    return request.put(`/yanxue-app/activity/share/${params.id}`, params)
}

// 删除分享
export function deleteShareActivity(params) {
    return request.delete(`/yanxue-app/activity/share/${params.id}`, { params })
}

/**
 * 活动资源模块
 * @param {*} params 
 * @returns 
 */

// 获取活动资源
export function getResource(params) {
    return request.get(`/yanxue-app/activity/resource/res`, { params })
}

// 获取所有资源
export function getResourceAll(params) {
    return request.get(`/yanxue-app/activity/resource/all`, { params })
}

// 添加活动资源
export function addResource(params) {
    return request.post(`/yanxue-app/activity/resource/res?activity_id=${params.activity_id}`, params)
}

// 删除活动资源
export function delResource(params) {
    return request.delete(`/yanxue-app/activity/resource/res`, { params })
}